import logo from './logo_oce3am.png';
import logo_text from './logoName_oce3am.png';
import logo_facebook from './facebook_logo.png';
import logo_instagram from './instagram_logo.png';
import logo_twitter from './twitter_logo.png';
import logo_linkedin from './linkedin_logo.png';

import services_1 from './services_1.png';
import services_2 from './services_2.png';
import picto_1 from './picto_01.png';
import picto_2 from './picto_02.png';
import picto_3 from './picto_03.png';
import picto_4 from './picto_04.png';
import picto_5 from './picto_05.png';
import picto_6 from './picto_06.png';

import PDF_flyer from "./OCE3AM_flyer.pdf";

import React from "react";
import SlickSlider from './Slick';

import './App.css';
import './Slick.css';

//<img src={services_1} className="App-services" alt="logo" />
//<img src={services_1} className="App-servicesDivBottomImgs" alt="logo" />
//<img src={services_2} className="App-servicesDivBottomImgs" alt="logo" />
/*
<p style={{fontWeight:"bold", fontSize:"4vmin"}} className="App-welcomeText">
            BIENVENUE CHEZ
</p> */

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-col">
          <div className="App-servicesDiv">
            <img src={picto_1} className="App-servicesPicto" alt="logo" />
            <p className="PictoDetails">Tous types de véhicules avec des produits écologiques.</p>
            <img src={picto_2} className="App-servicesPicto" alt="logo" />
            <p className="PictoDetails">Lustrage, désoxydation, régénération, céramique, phares, traitement anti-pluie et anti-impacts.</p>
            <img src={picto_3} className="App-servicesPicto" alt="logo" />
            <p className="PictoDetails">Toutes marques, réparation, démontage et remplacement.</p>
          </div>
          
          <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
            <div className="App-logoDiv">
              <img src={logo} className="App-logo" alt="logo" />
              <img src={logo_text} className="App-logoText" alt="logo" />
            </div>
            <p
              className="App-Title"
              style={{fontWeight: "bold"}}
              >
              Centre d'Entretien Ecologique et d'Esthétisme pour tous types de véhicules
            </p>
            <p style={{fontWeight:"bold", marginBottom:"0", marginTop:"5vmin"}} className="App-BuildText">
              SITE EN CONSTRUCTION
            </p>
            <p style={{fontWeight:"bold", fontSize:"4vmin", marginTop:"0"}}  className="App-SoonText">
              BIENTÔT EN LIGNE
            </p>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}} className="App-Info">
              <p className="App-Schedules">
                OUVERT du Lundi au Vendredi de 8h30 à 18h
                et le Samedi de 8h30 à 17h
              </p>
              <div className="App-AddressPhone">
                  <a
                    className="App-Address"
                    href="https://www.google.com/maps/place/OCE3AM/@43.4532436,1.9915963,17z/data=!3m1!4b1!4m5!3m4!1s0x12ae43f162c8ee6f:0x71e3a399efb8da85!8m2!3d43.4532436!4d1.993785"
                    target='_blank'
                    style={{fontWeight: "bold"}}
                  >
                    2 Rue Clémence Isaure 31250 REVEL
                  </a>
                  <a
                    className="App-Phone"
                    href="tel:0561200949"
                    target='_blank'
                    style={{fontWeight: "bold"}}
                  >
                    05 61 20 09 49
                  </a>
              </div>
            </div>
            <div className="App-SocialNetworks">
              <a
                className="App-link"
                href="https://www.facebook.com/oce3am"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_facebook} className="App-SocialNetworksLogo" alt="logo" />
              </a>
              <a
                className="App-link"
                href="https://www.instagram.com/oce3am/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_instagram} className="App-SocialNetworksLogo" alt="logo" />
              </a>
              <a
                className="App-link"
                href="https://twitter.com/OCE3AM"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_twitter} className="App-SocialNetworksLogo" alt="logo" />
              </a>
              <a
                className="App-link"
                href="https://www.linkedin.com/in/oce3am-france-2335a820b/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_linkedin} className="App-SocialNetworksLogo" alt="logo" />
              </a>
            </div>
            <div className="App-servicesDivBottom">
              <div className="App-servicesDiv2">
                <div style={{display:"flex", flexDirection:"column",alignItems:"center"}}>
                  <img src={picto_1} className="App-servicesPicto" alt="logo" style={{marginTop:"8px", marginRight:"4vmin"}}/>
                  <p className="PictoDetails" style={{marginRight:"4vmin"}}>Tous types de véhicules avec des produits écologiques.</p>
                </div>
                <div style={{display:"flex", flexDirection:"column",alignItems:"center"}}>
                  <img src={picto_2} className="App-servicesPicto" alt="logo" style={{marginLeft:"4vmin", marginRight:"4vmin"}}/>
                  <p className="PictoDetails">Lustrage, désoxydation, régénération, céramique, phares, traitement anti-pluie et anti-impacts.</p>
                </div> 
                <div style={{display:"flex", flexDirection:"column",alignItems:"center"}}>       
                  <img src={picto_3} className="App-servicesPicto" alt="logo" style={{marginLeft:"4vmin"}}/>
                  <p className="PictoDetails" style={{marginLeft:"4vmin"}}>Toutes marques, réparation, démontage et remplacement.</p>
                </div>
              </div>
              <div className="App-servicesDiv2" style={{marginTop:"2vmin"}}>
                <div style={{display:"flex", flexDirection:"column",alignItems:"center"}}>
                  <img src={picto_4} className="App-servicesPicto" alt="logo" style={{marginRight:"4vmin"}}/>
                  <p className="PictoDetails" style={{marginRight:"4vmin"}}>Nettoyage de pièces mécaniques ou tous supports. Pièces internes et externes.</p>
                </div>
                <div style={{display:"flex", flexDirection:"column",alignItems:"center"}}>
                  <img src={picto_5} className="App-servicesPicto" alt="logo" style={{marginLeft:"3vmin", marginRight:"4vmin"}}/>
                  <p className="PictoDetails">
                    Recharge avec juste la quantité de gaz nécessaire et avec un nouveau gaz 100% écologique !
                  </p>
                </div>
                <div style={{display:"flex", flexDirection:"column",alignItems:"center"}}>
                  <img src={picto_6} className="App-servicesPicto" alt="logo" style={{marginTop:"6px", marginLeft:"2vmin"}}/>
                  <p className="PictoDetails" style={{marginLeft:"5vmin"}}>
                    Offre unique de contrôle anti-pollution pour types de véhicules essence ou diesel avec une efficacité garantie.
                  </p>
                </div>
              </div>
          </div>

          <div className="App-servicesDivBottomMobile">
              <div className="App-servicesDiv2">
                <div style={{display:"flex", flexDirection:"column",alignItems:"center"}}>
                  <img src={picto_1} className="App-servicesPicto" alt="logo" style={{marginTop:"8px", marginRight:"5vmin"}}/>
                  <p className="PictoDetails" style={{marginRight:"5vmin"}}>Tous types de véhicules avec des produits écologiques.</p>
                </div>
                <div style={{display:"flex", flexDirection:"column",alignItems:"center"}}>
                  <img src={picto_2} className="App-servicesPicto" alt="logo" style={{marginLeft:"5vmin", marginRight:"5vmin"}}/>
                  <p className="PictoDetails">Lustrage, désoxydation, régénération, céramique, phares, traitement anti-pluie et anti-impacts.</p>
                </div> 
              </div>
              <div className="App-servicesDiv2">
                <div style={{display:"flex", flexDirection:"column",alignItems:"center"}}>       
                  <img src={picto_3} className="App-servicesPicto" alt="logo" style={{marginLeft:"3vmin"}}/>
                  <p className="PictoDetails" style={{marginLeft:"6vmin", marginRight:"4vmin"}}>Toutes marques, réparation, démontage et remplacement.</p>
                </div>
                <div style={{display:"flex", flexDirection:"column",alignItems:"center"}}>
                  <img src={picto_4} className="App-servicesPicto" alt="logo" style={{marginRight:"5vmin"}}/>
                  <p className="PictoDetails" style={{marginRight:"8vmin", marginLeft:"4vmin"}}>Nettoyage de pièces mécaniques ou tous supports. Pièces internes et externes.</p>
                </div>
              </div>
              <div className="App-servicesDiv2" style={{marginTop:"2vmin"}}>
                <div style={{display:"flex", flexDirection:"column",alignItems:"center"}}>
                  <img src={picto_5} className="App-servicesPicto" alt="logo" style={{marginLeft:"3vmin", marginRight:"4vmin"}}/>
                  <p className="PictoDetails" style={{marginRight:"3vmin", marginLeft:"4vmin"}}>
                    Recharge avec juste la quantité de gaz nécessaire et avec un nouveau gaz 100% écologique !
                  </p>
                </div>
                <div style={{display:"flex", flexDirection:"column",alignItems:"center"}}>
                  <img src={picto_6} className="App-servicesPicto" alt="logo" style={{marginTop:"6px", marginRight:"4vmin"}}/>
                  <p className="PictoDetails" style={{marginLeft:"3vmin", marginRight:"7vmin"}}>
                    Offre unique de contrôle anti-pollution pour types de véhicules essence ou diesel avec une efficacité garantie.
                  </p>
                </div>
              </div>
          </div>
          </div>

          <div className="App-servicesDiv">
            <img src={picto_4} className="App-servicesPicto" alt="logo" />
            <p className="PictoDetails">Nettoyage de pièces mécaniques ou tous supports. Pièces internes et externes.</p>
            <img src={picto_5} className="App-servicesPicto" alt="logo" />
            <p className="PictoDetails">
              Recharge avec juste la quantité de gaz nécessaire et avec un nouveau gaz 100% écologique !
            </p>
            <img src={picto_6} className="App-servicesPicto" alt="logo" />
            <p className="PictoDetails">
              Offre unique de contrôle anti-pollution pour types de véhicules essence ou diesel avec une efficacité garantie.
            </p>
          </div>
        </div>
        <SlickSlider/>

        <a
          href={PDF_flyer}
          className="Flyer-Btn"
          style={{textDecoration:"none"}}
          download
        >
          <p>Télécharger <br/> la brochure</p>
          <i class="fa fa-download btn"></i>
        </a>
      </header>
      <body>
      </body>
    </div>
  );
}

export default App;

/*Votre clim'auto est devenue moins performante ? LA SOLUTION CLIM' ECOLOGIQUE, ECONOMIQUE ET EFFICACE. (Gaz naturel non polluant et non toxique. Gaz 100% compatible avec R134A, 1234YF et R12)
            Au minimum 10 min pour votre recherge clim'écologique.*/

/* Pour les fumées blanches ou noires, contrôle anti-pollution non conforme aux Contrôles Techniques (AUTOSUR, DEKRA, SECURITEST, AUTOSECURITE, ...), Turbo, Vanne EGR, ...
            Tous types de véhicules essence ou diesel.
            Une offre unique sur le marché avec une efficacité garantie ou remboursée par OCE3AM (prix d'intervention + la contre visite au contrôle technique).
            À partir de 30 min de traitement écologique. */