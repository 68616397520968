import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React from "react";
import Slider from "react-slick";

import vehicle1 from "./vehicle1.png";
import vehicle2 from "./vehicle2.png";
import vehicle3 from "./vehicle3.png";
import vehicle4 from "./vehicle4.png";
import vehicle5 from "./vehicle5.png";
import vehicle6 from "./vehicle6.png";
import vehicle7 from "./vehicle7.png";
import vehicle8 from "./vehicle8.png";
import vehicle9 from "./vehicle9.png";
import vehicle10 from "./vehicle10.png";
import vehicle11 from "./vehicle11.png";
import vehicle12 from "./vehicle12.png";

export default function SlickSlider() {
  var settings = {
    infinite: true,    
    speed: 500,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    className: "App-Slider",
  };
  return (
    <Slider {...settings}>
        <img src={vehicle1} class="VehicleCard"/>
        <img src={vehicle2} class="VehicleCard"/>
        <img src={vehicle3} class="VehicleCard"/>
        <img src={vehicle4} class="VehicleCard"/>
        <img src={vehicle5} class="VehicleCard"/>
        <img src={vehicle6} class="VehicleCard"/>
        <img src={vehicle7} class="VehicleCard"/>
        <img src={vehicle8} class="VehicleCard"/>
        <img src={vehicle9} class="VehicleCard"/>
        <img src={vehicle10} class="VehicleCard"/>
        <img src={vehicle11} class="VehicleCard"/>
        <img src={vehicle12} class="VehicleCard"/>
    </Slider>
  );
}